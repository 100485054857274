import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function MyRoute({
  component: Component,
  isClosed,
  managerOnly,
  ...rest
}) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isManager = useSelector((state) => state.auth.user.isManager);

  if (isClosed && !isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { prevPath: rest.location.pathname } }}
      />
    );
  }

  if (managerOnly && isManager !== 1) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return <Route {...rest} component={Component} />;
}

MyRoute.defaultProps = {
  isClosed: false,
};

MyRoute.propTypes = {
  component: PropTypes.oneOf([PropTypes.element, PropTypes.func]).isRequired,
  isClosed: PropTypes.bool,
  managerOnly: PropTypes.bool,
};
