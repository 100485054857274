import React from 'react';
import { Container } from './styled';
import PropTypes from 'prop-types';

export default function List({ sales }) {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Septembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const monthData = [];

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  function getDate(sale) {
    return new Date(sale.date);
  }

  let januery = [];
  let february = [];
  let march = [];
  let april = [];
  let may = [];
  let june = [];
  let july = [];
  let august = [];
  let september = [];
  let october = [];
  let november = [];
  let december = [];

  for (let sale of sales) {
    for (let cancel of sale.Cancels) {
      cancel.client = sale.client;
      cancel.order = sale.order;
      cancel.type = 'cancelamento';
      switch (getDate(cancel).getMonth()) {
        case 0:
          januery.push(cancel);
          break;
        case 1:
          february.push(cancel);
          break;
        case 2:
          march.push(cancel);
          break;
        case 3:
          april.push(cancel);
          break;
        case 4:
          may.push(cancel);
          break;
        case 5:
          june.push(cancel);
          break;
        case 6:
          july.push(cancel);
          break;
        case 7:
          august.push(cancel);
          break;
        case 8:
          september.push(cancel);
          break;
        case 9:
          october.push(cancel);
          break;
        case 10:
          november.push(cancel);
          break;
        case 11:
          december.push(cancel);
          break;
        default:
          break;
      }
    }
    for (let dev of sale.Devolutions) {
      dev.type = 'devolucao';
      dev.client = sale.client;
      dev.order = sale.order;
      switch (getDate(dev).getMonth()) {
        case 0:
          januery.push(dev);
          break;
        case 1:
          february.push(dev);
          break;
        case 2:
          march.push(dev);
          break;
        case 3:
          april.push(dev);
          break;
        case 4:
          may.push(dev);
          break;
        case 5:
          june.push(dev);
          break;
        case 6:
          july.push(dev);
          break;
        case 7:
          august.push(dev);
          break;
        case 8:
          september.push(dev);
          break;
        case 9:
          october.push(dev);
          break;
        case 10:
          november.push(dev);
          break;
        case 11:
          december.push(dev);
          break;
        default:
          break;
      }
    }

    switch (getDate(sale).getMonth()) {
      case 0:
        januery.push(sale);
        break;
      case 1:
        february.push(sale);
        break;
      case 2:
        march.push(sale);
        break;
      case 3:
        april.push(sale);
        break;
      case 4:
        may.push(sale);
        break;
      case 5:
        june.push(sale);
        break;
      case 6:
        july.push(sale);
        break;
      case 7:
        august.push(sale);
        break;
      case 8:
        september.push(sale);
        break;
      case 9:
        october.push(sale);
        break;
      case 10:
        november.push(sale);
        break;
      case 11:
        december.push(sale);
        break;
      default:
        break;
    }
  }

  monthData.push(
    december,
    november,
    october,
    september,
    august,
    july,
    june,
    may,
    april,
    march,
    february,
    januery,
  );

  monthData.map((month) => {
    month.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
  });

  return (
    <Container>
      {monthData.map((month, index) => {
        {
          return month.length > 0 ? (
            <div className="wrapper" key={index}>
              <div id="month-title">
                {monthNames[getDate(month[0]).getMonth()]}
              </div>
              {month.map((item, index) => {
                return (
                  <div
                    className="item"
                    key={`${index}${item.id}${Math.random()}`}
                  >
                    <div className="small-text">{item.order}</div>
                    <div className="line">
                      <div>{item.client}</div>
                      <div
                        style={
                          item.type == 'cancelamento' ||
                          item.type === 'devolucao'
                            ? { color: 'red' }
                            : { color: '#28A745' }
                        }
                      >{`${
                        item.type == 'cancelamento' || item.type === 'devolucao'
                          ? '-'
                          : '+'
                      }${formatter.format(item.value)}`}</div>
                    </div>
                    <div className="line">
                      {item.type ? (
                        <div className="small-text">
                          {item.type == 'cancelamento'
                            ? 'Cancelamento'
                            : 'devolução'}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="small-text">
                        {getDate(item).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          );
        }
      })}
    </Container>
  );
}

List.propTypes = {
  sales: PropTypes.array,
};
