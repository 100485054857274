import React from "react";

const prizesData = [
  {
    prize: "Viagem para Expo Revestir 2025",
    description:
      "É a maior feira em matéria de revestimentos e acabamentos da América Latina. Durante o evento são apresentadas novas tendências e lançamentos para o setor. É uma ótima oportunidade para se atualizar e expandir seu networking.",
    layout: "col-span-2",
  },
  {
    prize: "Viagem para a Bogota Design Festival 2025, Colômbia",
    description:
      "Descubra a efervescência criativa no Bogotá Design Festival, um evento que celebra inovação e talento no design. Com palestras, workshops e exposições, reúne mentes visionárias para explorar as últimas tendências. Uma experiência única na capital colombiana para conectar entusiastas e profissionais do design.",
    layout: "col-span-3",
  },
  {
    prize: "Viagem para Cersaie 2025",
    description:
      "A Cersaie é uma renomada feira internacional dedicada ao setor de cerâmica e banheiro, realizada anualmente em Bolonha, Itália. Este evento é um ponto de encontro crucial para profissionais da indústria de design de interiores, arquitetura e construção, oferecendo uma plataforma única para a exibição das últimas tendências e inovações no campo dos revestimentos cerâmicos, louças sanitárias, móveis de banheiro, e outros produtos relacionados.",
    layout: "col-span-5",
  },
];

export default function PrizesSection() {
  return (
    <section className="max-w-5xl mx-auto w-full px-10" id="prizes">
      <div className="flex items-center justify-center flex-col gap-y-2 py-5">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white">
          Prêmios
        </h2>
        <p className="text-lg font-medium text-white">
          Os prêmios dessa edição foram pensados para enriquecer culturamente
          nossos grandes parceiros
        </p>
      </div>

      <div className="flex items-center justify-center flex-col lg:grid lg:grid-cols-5 gap-5 w-full">
        {prizesData.map((prize, index) => (
          <div
            key={index}
            className={`!border !p-7 !rounded-xl !bg-emerald-100 !drop-shadow-md !border-neutral-200/50 ${prize.layout} !flex !flex-col !gap-y-10 !justify-between`}
          >
            <div className="flex flex-col gap-y-3.5">
              <p className="!font-bold !text-xl !text-emerald-900">
                {prize.prize}
              </p>
              <p className="!font-medium !text-emerald-950">
                {prize.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
