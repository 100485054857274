import React from 'react';
import { Container } from './styled';
import { MaskedInput } from '../../../../components/MaskedInput';
import * as colors from '../../../../config/colors';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/modules/loading/actions';
import axios from '../../../../services/axios';

export default function Profile() {
  const [client, setClient] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [total, setTotal] = React.useState('');
  const [seller, setSeller] = React.useState('');
  const [date, setDate] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [architect, setArchitect] = React.useState('');
  const [actionValue, setActionValue] = React.useState('');
  const [actionDate, setActionDate] = React.useState('');
  const [type, setType] = React.useState('');
  const [saleId, setSaleId] = React.useState(null);

  const dispatch = useDispatch();

  const fields = [
    {
      id: 'client',
      type: 'text',
      value: client,
      setValue: setClient,
      placeHolder: 'Nome do cliente',
      label: 'Cliente',
      disabled: true,
    },
    {
      id: 'total',
      type: 'number',
      value: total,
      setValue: setTotal,
      placeHolder: 'Valor total da compra',
      label: 'Valor Total da Compra',
      disabled: true,
    },
    {
      id: 'seller',
      type: 'text',
      value: seller,
      setValue: setSeller,
      placeHolder: 'Nome do vendedor',
      label: 'Vendedor',
      disabled: true,
    },
    {
      id: 'date',
      type: 'text',
      value: date,
      setValue: setDate,
      placeHolder: 'Data da venda',
      label: 'Data da Venda',
      disabled: true,
    },
    {
      id: 'cpf',
      mask: '999.999.999-99',
      type: 'text',
      value: cpf,
      setValue: setCpf,
      placeHolder: 'CPF do arquiteto',
      label: 'CPF do Arquiteto',
      disabled: true,
    },
    {
      id: 'architect',
      type: 'text',
      value: architect,
      setValue: setArchitect,
      placeHolder: 'Nome do arquiteto',
      label: 'Nome do Arquiteto',
      disabled: true,
    },
  ];

  async function onSearchOrder() {
    try {
      if (!order) return;
      dispatch(actions.show());
      const { data } = await axios.get(`/sales/${order}`);
      if (!data) {
        dispatch(actions.dismiss());
        alert('Pedido não encontrado');
        return;
      }
      let formattedDate = new Date(data.date);
      setSaleId(data.id);
      setClient(data.client);
      setTotal(data.value);
      setSeller(data.seller);
      setDate(formattedDate.toLocaleDateString());
      setCpf(data.User.cpf);
      setArchitect(data.User.name);

      dispatch(actions.dismiss());
    } catch (error) {
      dispatch(actions.dismiss());
      console.log(error);
      alert(`${error}`);
    }
  }
  async function onSubmit() {
    if (actionValue < 0) {
      return alert('O valor total da ação deve ser um número positivo');
    }
    if (
      !client ||
      !order ||
      !seller ||
      !total ||
      !date ||
      !cpf ||
      !architect ||
      !type ||
      !actionValue ||
      !saleId ||
      !actionDate
    ) {
      return alert('Preencha todos os campos');
    }
    try {
      dispatch(actions.show());
      let data = null;
      let dateParts = actionDate.split('/');
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      if (type === 'cancelamento') {
        data = await axios.post(`/cancels`, {
          value: actionValue,
          sale: saleId,
          date: dateObject,
        });
      } else if (type === 'devolucao') {
        data = await axios.post(`/devolutions`, {
          value: actionValue,
          sale: saleId,
          date: dateObject,
        });
      }
      if (!data) {
        dispatch(actions.dismiss());
        return alert('Ocorreu um problema. O lançamento não foi salvo');
      }
      dispatch(actions.dismiss());
      alert('Lançamento salvo com sucesso');
      setActionValue('');
    } catch (error) {
      dispatch(actions.dismiss());
      console.log(error);
      alert(`${error.response.data.error.errors[0].message}`);
    }
  }

  return (
    <Container>
      <h1 id="title">Devoluções e Cancelamentos</h1>
      <div className="search-container">
        <MaskedInput
          key="order"
          id="order"
          type="text"
          value={order}
          setValue={setOrder}
          placeHolder="Insira o Número do Pedido"
          label="Pedido"
          disabled={false}
          style={{
            backgroundColor: 'white',
            border: `solid 0.5px ${colors.primary}`,
          }}
        />
        <div className="bt-container" id="search-btn-container">
          <button id="search-btn" onClick={onSearchOrder}>
            Pesquisar
          </button>
        </div>
      </div>
      <div className="input-container">
        {fields.map((field) => {
          if (field.id === 'total') {
            return (
              <MaskedInput
                key={field.id}
                id={field.id}
                mask={field.mask}
                type={field.type}
                value={field.value}
                setValue={field.setValue}
                placeHolder={field.placeHolder}
                label={field.label}
                disabled={field.disabled}
                style={
                  field.disabled
                    ? {
                        backgroundColor: 'white',
                        border: 'solid 0.5px #e2e6ea',
                      }
                    : {
                        backgroundColor: 'white',
                        border: `solid 0.5px ${colors.primary}`,
                      }
                }
              />
            );
          }
          return (
            <MaskedInput
              key={field.id}
              id={field.id}
              mask={field.mask}
              type={field.type}
              value={field.value}
              setValue={field.setValue}
              placeHolder={field.placeHolder}
              label={field.label}
              disabled={field.disabled}
              style={
                field.disabled
                  ? {
                      backgroundColor: 'white',
                      border: 'solid 0.5px #e2e6ea',
                    }
                  : {
                      backgroundColor: 'white',
                      border: `solid 0.5px ${colors.primary}`,
                    }
              }
            />
          );
        })}
      </div>
      <div className="third-container">
        <div className="select-container">
          <label style={{ paddingBottom: '10px' }}>Tipo</label>
          <Select
            onChange={(e) => {
              setType(e.value);
            }}
            placeholder="Tipo"
            options={[
              { value: 'devolucao', label: 'Devolução' },
              { value: 'cancelamento', label: 'Cancelamento' },
            ]}
            styles={{
              control: (style) => ({
                ...style,
                borderColor: `${colors.primary}`,
                height: '43px',
                borderRadius: '0px',
              }),
            }}
          />
        </div>
        <MaskedInput
          key="actionValue"
          id="actionValue"
          type="number"
          value={actionValue}
          setValue={setActionValue}
          placeHolder="Insira o valor total da ação"
          label="Valor Total da Ação"
          disabled={false}
          min={0}
          style={{
            backgroundColor: 'white',
            border: `solid 0.5px ${colors.primary}`,
          }}
        />
        <MaskedInput
          key="actionDate"
          id="actionDate"
          mask="99/99/9999"
          type="text"
          value={actionDate}
          setValue={setActionDate}
          placeHolder="Insira a data da ação"
          label="Data da Ação"
          disabled={false}
          style={{
            backgroundColor: 'white',
            border: `solid 0.5px ${colors.primary}`,
          }}
        />
      </div>
      <div className="bt-container">
        <button onClick={onSubmit}>Salvar</button>
      </div>
    </Container>
  );
}
