import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: black;
  background-color: #f5f7f9;
  #title {
    font-size: 28px;
    font-weight: bold;
    padding: 70px 0 30px 0;
  }
  #total {
    font-size: 50px;
    padding: 0 0 30px 0;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
  }
  @media (min-width: 900px) {
    #title {
      font-size: 28px;
      font-weight: bold;
      padding: 50px 0 30px 40px;
      width: 100%;
      float: right;
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
      float: right;
      width: 100%;
      padding-left: 40px;
    }
    h3 {
      font-size: 16px;
      float: right;
      width: 100%;
      padding-left: 40px;
    }
  }
`;
