import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styled";

export function ForgotPasswordModal({ title, msg, onYes }) {
  return (
    <>
      <Container>
        <div className="bg">
          <div className="box">
            <div className="title">{title}</div>
            <div className="msg-container">{msg}</div>
            <div className="button-container">
              <button
                onClick={() => {
                  onYes();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

ForgotPasswordModal.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};
