export const primary = '#0C3E35';
export const primaryDark = '#00158E';
export const primaryLight = '##2441E4';

export const secondary = '#E8C6AD';
export const secondaryDark = '#EF9506';
export const secondaryLight = '#F4AE3D';

export const error = '#EB5757';
export const success = '#56C568';
export const info = '#3FA2F7';
export const warning = '#FFF250';

export const errorLight = '#EF9595';
export const successLight = '#96D8A0';
export const infoLight = '#88C3F6';
export const warningLight = '#F8F4C2';

export const disabled = '#D1D3D5';
