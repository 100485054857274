/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Navbar from '../../pages/LandingPage/Navbar';
import Footer from '../../pages/LandingPage/Footer';

export function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center flex-col">
        <div className="prose p-6 text-white prose-headings:text-white">
          <h1 className="text-center">Política de Privacidade</h1>
          <h2>Lei Geral de Proteção de Dados</h2>
          <p>
            LGPD significa Lei Geral de Proteção de Dados e se refere à lei n.
            13709/2018, cuja finalidade é regulamentar a coleta, armazenamento e
            tratamento de dados pessoais em território nacional. Em nosso site,
            você trafega com total tranquilidade, pois temos certificado de
            segurança, garantindo toda a segurança para seus dados e atendendo
            todas as normas da legislação. Todas as informações que trafegam
            entre seu computador e o nosso site são codificadas utiliando a mais
            alta tecnologia disponível. Assim que recebemos seus dados, logo
            transferimos para nossa sede fora da internet, através de um seguro
            sistema de transmissão, totalmente criptografado.
          </p>
          <h2>Privacidade</h2>
          <p>
            Temos um enorme apreço pela individualidade e privacidade dos nossos
            usuários, tendo o máximo respeito pelo sigilo de informações obtidas
            através do nosso website. Nosso compromisso é manter sigilo absoluto
            de toda e qualquer informação de nossos usuários, respeitadas as
            exceções previstas em lei.
          </p>
          <h2>Uso das informações</h2>
          <p>
            As informações fornecidas por você serão mantidas em sigilo e serão
            utilizadas para inscrição, participação e atribuição de prêmios no
            Programa de Fidelidade da Elimacol Acabamentos. Todas as informações
            consideradas sigilosas, para efeito desta política, serão usadas
            exclusivamente por nós. Informações pessoais e individuais não serão
            vendidas ou repassadas pela Elimacol a terceiros sob nenhuma
            hipótese. O sigilo de suas informações e o respeito a você são de
            extrema importância para nós. Por isso, a Elimacol se compromete com
            a sua privacidade e segurança. Não divulgamos seus dados cadastrais
            para terceiros em hipótese alguma.
          </p>
          <h2>Cookies</h2>
          <p>
            Cookies são ferramentas essenciais na navegação online. Consistem em
            arquivos que identificam a interação entre você, nossos Sites e
            nossas redes sociais ou anúncios, e que são transferidos para o seu
            equipamento (computador, tablete ou smartphone) visando otimizar e
            personalizar a sua experiência de navegação nos Sites. O termo
            "cookie" pode ser usado para descrever uma série de tecnologias,
            incluindo, mas não se limitando a pixel tags, web beacons e
            identificadores de dispositivo móvel. A Elimacol usa tanto cookies
            de sessão quanto cookies persistentes. Os cookies de sessão coletam
            informações que não permitem a identificação do usuário e são
            automaticamente deletados quando a janela do seu navegador for
            fechada. Já os cookies persistentes são armazenados no seu
            computador ou dispositivo móvel até sua expiração e coletam
            informações que podem identificar o indivíduo. Além disso, os
            cookies utilizados nos Sites podem ser "proprietários", isto é,
            cookies definidos por nós - ou por um terceiro em nosso nome - ou
            "de terceiros", que permitem que recursos ou funcionalidades de
            terceiros confiáveis sejam fornecidos nos Sites ou por meio deles ou
            em serviços que você utiliza como anúncios, conteúdo interativo ou
            analytics. Esses terceiros podem reconhecer o seu dispositivo quando
            você navega em nossos Sites ou utiliza nossos serviços ou quando
            você navega em outros sites ou utiliza outros serviços. O uso de
            cookies é feito apenas para reconhecer um visitante constante e
            melhorar a experiência de compra. Os cookies são pequenos arquivos
            de dados transferidos de um site da web para o disco do seu
            computador e não armazenam dados pessoais. Se preferir, você pode
            apagar os cookies existentes em seu computador através do browser.
          </p>
          <h2>Alterações</h2>
          <p>
            A Elimacol poderá alterar a presente política de privacidade, no
            entanto qualquer alteração será veiculada em nosso site. Esta
            política não cria qualquer vínculo contratual entre a Elimacol e
            qualquer outra parte.
          </p>
          <h2>Contato</h2>
          <p>
            Em caso de dúvidas, contate-nos pelo e-mail:
            elimacol.acabamentos@gmail.com
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
