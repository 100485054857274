import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function LoginRoute({ component: Component, ...rest }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isManager = useSelector((state) => state.auth.user.isManager);
  const firstAccess = useSelector((state) => state.auth.user.firstAccess);

  if (isLoggedIn && firstAccess === 1) {
    return <Redirect to={{ pathname: '/firstaccess' }} />;
  }

  if (isLoggedIn && isManager === 0) {
    return <Redirect to={{ pathname: '/main' }} />;
  }
  if (isLoggedIn && isManager === 1) {
    return <Redirect to={{ pathname: '/manage' }} />;
  }

  return <Route {...rest} component={Component} />;
}

LoginRoute.defaultProps = {
  isClosed: false,
};

LoginRoute.propTypes = {
  component: PropTypes.oneOf([PropTypes.element, PropTypes.func]).isRequired,
  isClosed: PropTypes.bool,
  managerOnly: PropTypes.bool,
};
