import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  color: #2e2e2e;
  font-size: 14px;
  div {
    padding: 5px;
  }
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    width: 300px;
    height: 160px;
    border-radius: 20px;
    background-color: #f8f4c2;
  }
  .title {
    color: #2e2e2e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .msg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button {
      color: #2e2e2e;
      background: none;
      border: solid 1px #2e2e2e;
      border-radius: 10px;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-left: 7%;
      margin-right: 7%;
      width: 35%;
      font-size: 16px;

      :hover {
        background: ${colors.warning};
        filter: brightness(1);
        color: #2e2e2e;
        border: none;
      }
    }
  }
  #title-img {
    position: relative;
    right: 65px;
  }
  #title-msg {
    position: absolute;
  }
`;
