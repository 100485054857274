import React from 'react';
import { Container } from './styled';
import { MaskedInput } from '../../../../components/MaskedInput';
import * as colors from '../../../../config/colors';
import axios from '../../../../services/axios';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/modules/loading/actions';

export default function Profile() {
  const [client, setClient] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [total, setTotal] = React.useState('');
  const [seller, setSeller] = React.useState('');
  const [date, setDate] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [architect, setArchitect] = React.useState('');
  const [architectId, setArchitectId] = React.useState('');

  const dispatch = useDispatch();

  const fields = [
    {
      id: 'client',
      type: 'text',
      value: client,
      setValue: setClient,
      placeHolder: 'Insira o nome do cliente',
      label: 'Cliente',
      disabled: false,
    },
    {
      id: 'order',
      type: 'text',
      value: order,
      setValue: setOrder,
      placeHolder: 'Insira o número do pedido',
      label: 'Pedido',
      disabled: false,
    },
    {
      id: 'total',
      type: 'number',
      value: total,
      setValue: setTotal,
      placeHolder: 'Insira o valor total da compra',
      label: 'Valor Total da Compra',
      disabled: false,
    },
    {
      id: 'seller',
      type: 'text',
      value: seller,
      setValue: setSeller,
      placeHolder: 'Insira o nome do vendedor',
      label: 'Vendedor',
      disabled: false,
    },
    {
      id: 'date',
      mask: '99/99/9999',
      type: 'text',
      value: date,
      setValue: setDate,
      placeHolder: 'Insira a data da venda',
      label: 'Data da Venda',
      disabled: false,
    },
    {
      id: 'cpf',
      mask: '999.999.999-99',
      type: 'text',
      value: cpf,
      setValue: setCpf,
      placeHolder: 'Insira do CPF do arquiteto',
      label: 'CPF do Arquiteto',
      disabled: false,
    },
    {
      id: 'architect',
      type: 'text',
      value: architect,
      setValue: setArchitect,
      placeHolder: 'Nome do arquiteto',
      label: 'Nome do Arquiteto',
      disabled: true,
    },
  ];

  async function onArchitectBlur() {
    try {
      if (!cpf) return;
      dispatch(actions.show());
      const { data } = await axios.get(`/users/${cpf}`);
      if (!data) {
        dispatch(actions.dismiss());
        alert('Arquiteto não encontrado');
        return;
      }
      setArchitectId(data.id);
      setArchitect(data.name);

      dispatch(actions.dismiss());
    } catch (error) {
      dispatch(actions.dismiss());
      alert(`${error.response.data.error.errors[0].message}`);
    }
  }

  async function onSubmit() {
    if (
      !client ||
      !order ||
      !seller ||
      !total ||
      !date ||
      !cpf ||
      !architect ||
      !architectId
    ) {
      return alert('Preencha todos os campos');
    }
    let dateParts = date.split('/');
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    try {
      dispatch(actions.show());
      const { data } = await axios.post(`/sales`, {
        client,
        order,
        value: total,
        seller,
        date: dateObject,
        architect: architectId,
      });
      if (!data) {
        dispatch(actions.dismiss());
        return alert('Ocorreu um problema. O lançamento não foi salvo');
      }
      dispatch(actions.dismiss());
      setClient('');
      setOrder('');
      setTotal('');
      setSeller('');
      setDate('');
      alert('Lançamento salvo com sucesso');
    } catch (error) {
      dispatch(actions.dismiss());
      alert(`${error.response.data.error.errors[0].message}`);
    }
  }

  return (
    <Container>
      <h1 id="title">Inserir Compra</h1>
      <div className="input-container">
        {fields.map((field) => {
          if (field.id === 'cpf') {
            return (
              <MaskedInput
                key={field.id}
                id={field.id}
                mask={field.mask}
                type={field.type}
                value={field.value}
                setValue={field.setValue}
                placeHolder={field.placeHolder}
                label={field.label}
                disabled={field.disabled}
                onBlur={onArchitectBlur}
                style={
                  field.disabled
                    ? {
                        backgroundColor: 'white',
                        border: 'solid 0.5px #e2e6ea',
                      }
                    : {
                        backgroundColor: 'white',
                        border: `solid 0.5px ${colors.primary}`,
                      }
                }
              />
            );
          }
          return (
            <MaskedInput
              key={field.id}
              id={field.id}
              mask={field.mask}
              type={field.type}
              value={field.value}
              setValue={field.setValue}
              placeHolder={field.placeHolder}
              label={field.label}
              disabled={field.disabled}
              style={
                field.disabled
                  ? {
                      backgroundColor: 'white',
                      border: 'solid 0.5px #e2e6ea',
                    }
                  : {
                      backgroundColor: 'white',
                      border: `solid 0.5px ${colors.primary}`,
                    }
              }
            />
          );
        })}
      </div>
      <div className="bt-container">
        <button onClick={onSubmit}>Salvar</button>
      </div>
    </Container>
  );
}
