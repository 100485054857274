import React from "react";
import heroImage from "../../../assets/bg-image-8.png";
import flower1 from "../../../assets/flower-1.svg";
import flower2 from "../../../assets/flower-2.svg";
import flower3 from "../../../assets/flower-3.svg";
import flower4 from "../../../assets/flower-4.svg";

function HeroSection() {
  const subscribeFloresca = () => {
    window.open("https://forms.gle/USX6Pi6ji2vJApqA9", "_blank");
  };
  return (
    <div className="hero" id="home">
      <div className="main-hero-section max-w-5xl relative flex flex-col gap-y-2 items-center justify-center mx-auto py-14 px-10 md:px-10 xl:px-0">
        <div className="hero-content lg:px-5 flex flex-col items-center gap-y-5 ">
          <img
            className="absolute w-32 -top-10 left-0 lg:-top-10 lg:-left-32 lg:w-60"
            src={flower1}
            alt=""
          />
          <img
            className="lg:flex absolute hidden lg:top-0 lg:-right-40 lg:w-60"
            src={flower2}
            alt=""
          />
          <img
            className="absolute w-40 -bottom-20 left-0 lg:-bottom-36 lg:-left-48 lg:w-60"
            src={flower3}
            alt=""
          />
          <img
            className="absolute w-40 -bottom-10 right-0 lg:-bottom-32 lg:right-0 lg:w-60"
            src={flower4}
            alt=""
          />

          <h1 className="text-4xl text-white md:text-5xl xl:text-[60px] leading-[1.2] md:max-w-xl mx-auto text-center lg:max-w-3xl font-semibold">
            Floresça com a Elimacol
          </h1>
          <p className="text-sm lg:text-lg max-w-[16rem] mx-auto md:max-w-xl md:mx-auto lg:max-w-2xl text-white text-center">
            Acumule compras e concorra a prêmios exclusivos
          </p>
          <button
            onClick={subscribeFloresca}
            className="!bg-rose-200 !text-emerald-900 !w-2/5  md:!w-1/5 !flex !items-center !justify-center hover:!shadow-lg !border !border-transparent hover:!drop-shadow !transition !duration-200  !font-semibold !text-xs lg:!text-base !rounded-xl !px-5 !py-3"
          >
            Inscreva-se
          </button>
        </div>
        <div></div>
      </div>
      <div className=" flex items-center justify-center gap-5 p-10 max-w-6xl mx-auto rounded-none lg:rounded-2xl overflow-hidden">
        <img className="rounded-xl bg-white" src={heroImage} alt="" />
      </div>
    </div>
  );
}

export default HeroSection;
