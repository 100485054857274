import styled from 'styled-components';
import * as colors from '../../../../config/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  position: absolute;
  background-color: #f5f7f9;
  .confirmation-container {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .bt-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-container {
    width: 100%;
  }
  #title {
    font-size: 28px;
    font-weight: bold;
    padding: 160px 0 30px 0;
  }
  #links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    height: 100%;
    position: absolute;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 40px;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 0.5px solid;
      width: 100%;
    }
  }
  button {
    margin: 20px 0 40px 0;
    height: 60px;
    width: 70%;
    color: white;
    background-color: ${colors.primary};
    font-size: 26px;
    :hover {
      color: ${colors.primary};
      background-color: white;
    }
  }
  @media (min-width: 900px) {
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #f5f7f9;
    padding-left: 100px;
    height: 100%;
    #title {
      padding: 40px;
    }
    #links-container {
      position: absolute;
      width: 240px;
      padding-left: 30px;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        height: 50px;
        font-size: 18px;
        font-weight: bold;
        border: 0.5px solid ${colors.primary};
        width: 100%;
        color: ${colors.primary};
        padding: 15px;
        background-color: white;
        :hover {
          background-color: ${colors.primary};
          color: white;
        }
      }
    }
    .input-container {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
    }
    button {
      height: 60px;
      width: 300px;
      color: white;
      background-color: ${colors.primary};
      font-size: 22px;
    }
    .bt-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .confirmation-container {
      width: 100%;
      padding: 0 10% 0 10%;
      display: grid;
      grid-template-columns: auto auto;
    }
  }
`;
