import React from "react";
import PropTypes from "prop-types";
import atention from "./res/IconeAtencao.svg";

import { Container } from "./styled";

export function ConfirmationModal({ msg, onYes, onNo }) {
  return (
    <>
      <Container>
        <div className="bg">
          <div className="box">
            <div className="title">
              <div id="title-img">
                <img src={atention} />
              </div>
              <div id="title-msg">ATENÇÃO!</div>
            </div>
            <div className="msg-container">{msg}</div>
            <div className="button-container">
              <button
                onClick={() => {
                  onNo();
                }}
              >
                Não
              </button>
              <button
                onClick={() => {
                  onYes();
                }}
              >
                Sim
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

ConfirmationModal.propTypes = {
  msg: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};
