import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  #nav-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    top: 0;
    padding: 30px 0 30px 0;
  }

  .nav-icon {
    filter: invert(94%) sepia(2%) saturate(134%) hue-rotate(314deg)
      brightness(87%) contrast(86%);
  }
  .nav-icon-active {
    filter: invert(17%) sepia(75%) saturate(393%) hue-rotate(119deg)
      brightness(98%) contrast(99%);
  }
  .nav-icon:hover {
    filter: invert(17%) sepia(75%) saturate(393%) hue-rotate(119deg)
      brightness(98%) contrast(99%);
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    #nav-bar {
      height: 100vh;
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      top: 0;
      padding: 30px 0 30px 0;
    }
  }
`;
