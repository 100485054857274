import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';
import LoginRoute from './LoginRoute';
import FirstAccess from '../pages/FirstAccess';
import Access from '../pages/Access';
import { Content as LandingPage } from '../pages/LandingPage';
import Page404 from '../pages/Page404';
import Main from '../pages/Main';
import Manager from '../pages/Manager';
import { PrivacyPolicy } from '../components/PrivacyPolicy';

export default function AppRoutes() {
  return (
    <Switch>
      <MyRoute exact path="/" component={LandingPage} />
      <LoginRoute exact path="/login" component={Access} />
      <MyRoute
        exact
        path="/firstaccess"
        component={FirstAccess}
        isClosed={true}
      />
      <MyRoute exact path="/main" component={Main} isClosed={true} />
      <MyRoute
        exact
        path="/manage"
        component={Manager}
        isClosed={true}
        managerOnly
      />
      <MyRoute path="/privacy-policy" component={PrivacyPolicy} />
      <MyRoute path="*" component={Page404} />
    </Switch>
  );
}
