import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Container } from './styled';

export default function Loading() {
  const isLoading = useSelector((state) => state.loading.isLoading);

  if (!isLoading) return <></>;
  return (
    <Container>
      <div />
      <span>
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </span>
    </Container>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};
