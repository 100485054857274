import React from 'react';
import { Container } from './styled';
import arrow from './res/arrow.svg';
import back from './res/back.svg';
import { MaskedInput } from '../../../../components/MaskedInput';
import * as colors from '../../../../config/colors';
import { useEffect } from 'react';
import axios from '../../../../services/axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/modules/auth/actions';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { toast } from 'react-toastify';

export default function Profile() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userOriginalData, setUserOriginalData] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [name, setName] = React.useState('');
  const [birthDate, setBirthDate] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [cel, setCel] = React.useState('');
  const [cep, setCep] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [neighborhood, setNeighborhood] = React.useState('');
  const [city, setCity] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [displayPersonalData, setDisplayPersonalData] = React.useState(false);
  const [displayChangePassword, setDisplayChangePassword] =
    React.useState(false);

  const id = useSelector((state) => state.auth.user.id);
  const fields = [
    {
      id: 'cpf',
      mask: '999.999.999-99',
      type: 'text',
      value: cpf,
      setValue: setCpf,
      placeHolder: '000.000.000-00',
      label: 'CPF',
      disabled: true,
    },
    {
      id: 'name',
      type: 'text',
      value: name,
      setValue: setName,
      placeHolder: 'Seu nome',
      label: 'Nome Completo',
      disabled: true,
    },
    {
      id: 'birthDate',
      type: 'text',
      value: birthDate,
      setValue: setBirthDate,
      placeHolder: 'Sua data de nascimento',
      label: 'Data de Nascimento',
      disabled: true,
    },
    {
      id: 'email',
      type: 'text',
      value: email,
      setValue: setEmail,
      placeHolder: 'Digite seu e-mail',
      label: 'E-mail',
      disabled: false,
    },
    {
      id: 'cel',
      type: 'text',
      mask: '(99) 9 9999-9999',
      value: cel,
      setValue: setCel,
      placeHolder: 'Digite um número de telefone',
      label: 'Telefone',
      disabled: false,
    },
    {
      id: 'cep',
      type: 'text',
      mask: '99999-999',
      value: cep,
      setValue: setCep,
      placeHolder: 'Digite seu CEP',
      label: 'CEP',
      disabled: false,
    },
    {
      id: 'address',
      type: 'text',
      value: address,
      setValue: setAddress,
      placeHolder: 'Digite sua rua',
      label: 'Endereço',
      disabled: false,
    },
    {
      id: 'Numero',
      type: 'text',
      value: number,
      setValue: setNumber,
      placeHolder: 'Digite seu número',
      label: 'Número',
      disabled: false,
    },
    {
      id: 'info',
      type: 'text',
      value: info,
      setValue: setInfo,
      placeHolder: 'Insira informações adicionais sobre seu endereço',
      label: 'Complemento',
      disabled: false,
    },
    {
      id: 'neighborhood',
      type: 'text',
      value: neighborhood,
      setValue: setNeighborhood,
      placeHolder: 'Digite seu bairro',
      label: 'Bairro',
      disabled: false,
    },
    {
      id: 'city',
      type: 'text',
      value: city,
      setValue: setCity,
      placeHolder: 'Digite sua cidade',
      label: 'Cidade',
      disabled: false,
    },
  ];

  const passwordFields = [
    {
      id: 'password',
      type: 'password',
      value: newPassword,
      setValue: setNewPassword,
      placeHolder: 'Digite sua nova senha',
      label: 'Nova Senha',
      disabled: false,
    },
    {
      id: 'passwordConfirmation',
      type: 'password',
      value: passwordConfirmation,
      setValue: setPasswordConfirmation,
      placeHolder: 'Confirme sua nova senha',
      label: 'Confirmar Senha',
      disabled: false,
    },
  ];

  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(`/users/show/${id}`);
      setUserOriginalData(data);
      setCpf(data.cpf);
      setName(data.name);
      let dateObj = new Date(data.birthDate);
      setBirthDate(dateObj.toLocaleDateString());
      setEmail(data.email);
      setCel(data.telephoneNumber);
      setCep(data.cep);
      setAddress(data.address);
      setNumber(data.addressNumber);
      setInfo(data.additionalInformation);
      setNeighborhood(data.neighborhood);
      setCity(data.city);
    }
    getData();
  }, [id]);

  async function handleSubmit() {
    if (displayPersonalData) {
      if (
        !email ||
        !cel ||
        !cep ||
        !address ||
        !number ||
        !neighborhood ||
        !city
      ) {
        return toast.error(
          'Todos os campos, exceto "Complemento" precisam estar preenchidos',
        );
      }
    } else if (displayChangePassword) {
      if (newPassword.length < 6) {
        return toast.error('Digite uma senha com pelo menos 6 caracteres');
      }
      if (newPassword != passwordConfirmation) {
        return toast.error('As senhas precisam ser iguais');
      }
    }
    setIsModalOpen(true);
  }
  function hideModal() {
    setIsModalOpen(false);
  }
  function onYes() {
    if (displayPersonalData) {
      dispatch(
        actions.registerRequest({
          id,
          email,
          telephoneNumber: cel,
          cep,
          address,
          addressNumber: number,
          additionalInformation: info,
          neighborhood,
          city,
        }),
      );
    } else if (displayChangePassword) {
      dispatch(
        actions.registerRequest({
          id,
          password: newPassword,
        }),
      );
    }
    hideModal();
    setNewPassword('');
    setPasswordConfirmation('');
  }

  return (
    <Container>
      {isModalOpen ? (
        <ConfirmationModal
          msg={'Confirmar alterações?'}
          onNo={hideModal}
          onYes={onYes}
        />
      ) : (
        ''
      )}
      <div className="big-title-container">
        {displayChangePassword || displayPersonalData ? (
          <img
            src={back}
            className="back-button"
            onClick={() => {
              setDisplayChangePassword(false);
              setDisplayPersonalData(false);
              setNewPassword('');
              setPasswordConfirmation('');
              setDisplayChangePassword(false);
              setCpf(userOriginalData.cpf);
              setName(userOriginalData.name);
              let dateObj = new Date(userOriginalData.birthDate);
              setBirthDate(dateObj.toLocaleDateString());
              setEmail(userOriginalData.email);
              setCel(userOriginalData.telephoneNumber);
              setCep(userOriginalData.cep);
              setAddress(userOriginalData.address);
              setNumber(userOriginalData.addressNumber);
              setInfo(userOriginalData.additionalInformation);
              setNeighborhood(userOriginalData.neighborhood);
              setCity(userOriginalData.city);
              setDisplayPersonalData(false);
            }}
          />
        ) : (
          ''
        )}

        <div className="title-container">
          <h1 id="title">Perfil</h1>
        </div>
      </div>

      {!(displayChangePassword || displayPersonalData) && (
        <div id="links-container">
          <div
            className="field"
            onClick={() => {
              if (displayChangePassword) setDisplayChangePassword(false);
              setDisplayPersonalData(true);
            }}
          >
            Dados Pessoais <img src={arrow} />
          </div>
          <div
            onClick={() => {
              if (displayPersonalData) setDisplayPersonalData(false);
              setDisplayChangePassword(true);
            }}
          >
            Senha <img src={arrow} />
          </div>
        </div>
      )}
      <div className="input-container">
        {displayPersonalData &&
          fields.map((field) => {
            return (
              <MaskedInput
                key={field.id}
                id={field.id}
                mask={field.mask}
                type={field.type}
                value={field.value}
                setValue={field.setValue}
                placeHolder={field.placeHolder}
                label={field.label}
                disabled={field.disabled}
                style={
                  field.disabled
                    ? {
                        backgroundColor: 'white',
                        border: 'solid 0.5px #e2e6ea',
                      }
                    : {
                        backgroundColor: 'white',
                        border: `solid 0.5px ${colors.primary}`,
                      }
                }
              />
            );
          })}
      </div>
      <div className="confirmation-container">
        {displayChangePassword &&
          passwordFields.map((field) => {
            return (
              <MaskedInput
                key={field.id}
                id={field.id}
                mask={field.mask}
                type={field.type}
                value={field.value}
                setValue={field.setValue}
                placeHolder={field.placeHolder}
                label={field.label}
                disabled={field.disabled}
                style={
                  field.disabled
                    ? {
                        backgroundColor: 'white',
                        border: 'solid 0.5px #e2e6ea',
                      }
                    : {
                        backgroundColor: 'white',
                        border: `solid 0.5px ${colors.primary}`,
                      }
                }
              />
            );
          })}
      </div>
      {(displayChangePassword || displayPersonalData) && (
        <div className="bt-container">
          <button
            onClick={handleSubmit}
            style={{ border: `solid 0.5px ${colors.primary}` }}
          >
            Salvar
          </button>
        </div>
      )}
    </Container>
  );
}
