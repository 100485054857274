import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;

  input {
    width: 100%;
    height: 100%;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    background: #f3f0d9;
    font-size: 16px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
  }
  label {
    padding-bottom: 10px;
  }
`;
