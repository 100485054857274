import React from 'react';
import { Link } from 'react-router-dom';

import { NavBar } from '../../components/NavBar';
import { MaskedInput } from '../../components/MaskedInput';
import { Button, Container } from './styled';
import banner from '../../public/banner-login.svg';
import * as colors from '../../config/colors';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/modules/auth/actions';
import { toast } from 'react-toastify';
import logo from '../../public/Logo.svg';
import Loading from '../../components/UncontrolledLoading';

export default function FirstAccess() {
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const isLoading = useSelector((state) => state.auth.isLoading);

  const dispatch = useDispatch();

  const id = useSelector((state) => state.auth.user.id);

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  function handleClick() {
    if (newPassword.length < 6) {
      return toast.error('Digite uma senha com pelo menos 6 caracteres');
    }
    if (newPassword != passwordConfirmation) {
      return toast.error('As senhas precisam ser iguais');
    }
    dispatch(
      actions.passwordUpdateRequest({
        id,
        password: newPassword,
        firstAccess: 0,
      }),
    );
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      {isLoading ? <Loading /> : ''}
      {width < 900 && <NavBar isLoading={true} />}
      {width > 900 && (
        <>
          <img
            src={banner}
            style={{
              width: '100vw',
              position: 'absolute',
              bottom: '0px',
            }}
          />
          <Link to={'/'}>
            <img
              src={logo}
              style={{
                width: '200px',
                position: 'absolute',
                top: '40px',
                left: '40px',
              }}
            />
          </Link>
        </>
      )}
      <div
        style={
          width <= 900
            ? { height: '100vh', width: '100vw' }
            : {
                height: '100vh',
                width: '63vw',
                left: '0px',
                backgroundColor: 'white',
                float: 'right',
                borderRadius: '88px 0 0 88px',
              }
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h1
            style={
              width < 900
                ? { paddingTop: '20px' }
                : { paddingTop: '100px', color: `${colors.primary}` }
            }
          >
            É o seu primeiro acesso!
          </h1>
        </div>
        {width <= 900 ? (
          <>
            <div style={{ marginTop: '80px' }}>
              <MaskedInput
                id="newPassword"
                type="password"
                value={newPassword}
                setValue={setNewPassword}
                placeHolder="Insira sua nova senha"
                label={'Nova Senha'}
              />
              <MaskedInput
                id="password"
                type="password"
                value={passwordConfirmation}
                setValue={setPasswordConfirmation}
                placeHolder="Digite sua senha"
                label={'Senha'}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justfyContent: 'center',
                paddingTop: '50px',
              }}
            >
              <Button type="button" onClick={handleClick}>
                Acessar
              </Button>
            </div>
          </>
        ) : (
          <Container>
            <MaskedInput
              id="newPassword"
              type="password"
              value={newPassword}
              setValue={setNewPassword}
              placeHolder="Digite sua nova senha"
              label={'Nova Senha'}
            />
            <MaskedInput
              id="passwordConfirmation"
              type="password"
              value={passwordConfirmation}
              setValue={setPasswordConfirmation}
              placeHolder="Confirme sua nova senha"
              label={'Confirmar Senha'}
            />
            <button id="access-bt" onClick={handleClick}>
              Acessar
            </button>
          </Container>
        )}
      </div>
    </div>
  );
}
