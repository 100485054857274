import styled from 'styled-components';
import * as colors from '../../config/colors';

export const StyledButton = styled.button`
  border: 0.5px solid ${colors.secondary};
  background: none;
  padding: 15px;
  color: ${colors.secondary};

  :hover {
    color: ${colors.primary};
    background-color: ${colors.secondary};
    a {
      color: ${colors.primary};
    }
  }
  a {
    color: ${colors.secondary};
  }
`;
