import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { NavBar } from "../../components/NavBar2";
import { MaskedInput } from "../../components/MaskedInput";
import { Button, Container } from "./styled";
import banner from "../../public/banner-login.svg";
import * as colors from "../../config/colors";
import * as actions from "../../store/modules/auth/actions";
import * as loadingActions from "../../store/modules/loading/actions";
import { ForgotPasswordModal } from "../../components/ForgotPasswordModal";
import logo from "../../assets/logo.svg";
import Loading from "../../components/UncontrolledLoading";
import Navbar from "../LandingPage/Navbar";

export default function Access(props) {
  const [cpf, setCpf] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    React.useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const dispatch = useDispatch();

  const prevPath = get(props, "location.state.prevPath", "/");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loadingActions.show());
    if (cpf.length != 14) {
      dispatch(loadingActions.dismiss());
      toast.error("Confira seu CPF");
    }
    if (!password) {
      dispatch(loadingActions.dismiss());
      toast.error("Digite sua senha");
    }

    dispatch(actions.loginRequest({ cpf, password, prevPath }));
    dispatch(loadingActions.dismiss());
  };

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  function onOk() {
    setIsForgotPasswordModalOpen(false);
  }

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      {isLoading ? <Loading /> : ""}

      {isForgotPasswordModalOpen ? (
        <ForgotPasswordModal
          title={"Entre em contato conosco!"}
          msg={
            "Você pode entrar em contato pelo número (31) 97327-7400, ou, se preferir, fale com um dos nossos gerentes"
          }
          onYes={onOk}
        />
      ) : (
        ""
      )}

      {width < 900 && <Navbar />}
      {width > 900 && (
        <>
          <img
            src={banner}
            style={{
              width: "100vw",
              position: "absolute",
              bottom: "0px",
            }}
          />
          <Link to={"/"}>
            <img
              src={logo}
              style={{
                width: "200px",
                position: "absolute",
                top: "40px",
                left: "40px",
              }}
            />
          </Link>
        </>
      )}
      <div
        style={
          width <= 900
            ? { height: "100vh", width: "100vw" }
            : {
                height: "100vh",
                width: "63vw",
                left: "0px",
                backgroundColor: "white",
                float: "right",
                borderRadius: "88px 0 0 88px",
              }
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <h1
            style={
              width < 900
                ? { paddingTop: "20px" }
                : { paddingTop: "100px", color: `${colors.primary}` }
            }
            className="text-5xl font-bold"
          >
            Seja bem-vindo!
          </h1>
        </div>
        {width <= 900 ? (
          <>
            <div style={{ marginTop: "80px" }}>
              <MaskedInput
                id="cpf"
                mask={"999.999.999-99"}
                type="text"
                value={cpf}
                setValue={setCpf}
                placeHolder="Digite seu CPF"
                label={"CPF"}
              />
              <MaskedInput
                id="password"
                type="password"
                value={password}
                setValue={setPassword}
                placeHolder="Digite sua senha"
                label={"Senha"}
              />
            </div>
            <div
              id="forgot-password"
              style={{ paddingLeft: "7%" }}
              onClick={() => {
                console.log("oi");
                setIsForgotPasswordModalOpen(true);
              }}
            >
              Esqueceu sua senha?
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justfyContent: "center",
                paddingTop: "50px",
              }}
            >
              <Button
                type="button"
                onClick={handleSubmit}
                className="bg-emerald-200 text-emerald-900 font-bold px-5 py-2 mt-0 rounded-xl shadow-md hover:bg-emerald-600 hover:text-emerald-100"
              >
                Acessar
              </Button>
            </div>
          </>
        ) : (
          <Container>
            <MaskedInput
              id="cpf"
              mask={"999.999.999-99"}
              type="text"
              value={cpf}
              setValue={setCpf}
              placeHolder="Digite seu CPF"
              label={"CPF"}
            />
            <MaskedInput
              id="password"
              type="password"
              value={password}
              setValue={setPassword}
              placeHolder="Digite sua senha"
              label={"Senha"}
            />
            <div
              id="forgot-password"
              style={{ paddingLeft: "10px", color: `${colors.primary}` }}
              onClick={() => {
                console.log("oi");
                setIsForgotPasswordModalOpen(true);
              }}
            >
              Esqueceu sua senha?
            </div>
            <button
              id="access-bt"
              onClick={handleSubmit}
              className="bg-emerald-200 text-emerald-900 font-bold px-5 py-2 mt-20 rounded-xl shadow-md hover:bg-emerald-600 hover:text-emerald-100"
            >
              Acessar
            </button>
          </Container>
        )}
      </div>
    </div>
  );
}
