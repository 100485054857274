import React from 'react';
import { Container } from './styled';
import List from './List';
import { MaskedInput } from '../../../../components/MaskedInput';
import * as colors from '../../../../config/colors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/modules/loading/actions';
import axios from '../../../../services/axios';

export default function Summary() {
  const [cpf, setCpf] = useState('');
  const [sales, setSales] = useState([]);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const calcTotal = (data) => {
    let sumCancel = 0;
    let sumDev = 0;

    data.forEach((sale) => {
      sumCancel += sale.Cancels.reduce((accumulator, current) => {
        return accumulator + current.value;
      }, 0);
    });
    data.forEach((sale) => {
      sumDev += sale.Devolutions.reduce((accumulator, current) => {
        return accumulator + current.value;
      }, 0);
    });
    let sumSales = data.reduce((accumulator, current) => {
      return accumulator + current.value;
    }, 0);
    return sumSales - sumCancel - sumDev;
  };

  async function getData() {
    try {
      dispatch(actions.show());
      const userData = await axios.get(`/users/${cpf}`);
      const user = userData.data;
      if (!user) return alert('Arquiteto não encontrado');
      const { data } = await axios.get(`/sales/archId/${user.id}`);
      if (data.length == 0)
        return alert('Esse arquiteto não possui nenhuma venda');
      setSales(data);
      setTotal(calcTotal(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(actions.dismiss());
    }
  }

  function handleClick() {
    if (cpf.length != 14) {
      return alert('Preencha um CPF válido');
    }
    getData();
  }
  let lastUpdate = null;

  if (sales[0]) {
    lastUpdate = new Date(sales[0].created_at);
  }

  return (
    <Container>
      <h1 id="title">Compas Acumuladas</h1>
      <div className="search-container">
        <div style={{ width: '400px' }}>
          <MaskedInput
            key="cpf"
            id="cpf"
            mask={'999.999.999-99'}
            type="text"
            value={cpf}
            setValue={setCpf}
            placeHolder="Insira o CPF do arquiteto"
            label="CPF"
            disabled={false}
            style={{
              backgroundColor: 'white',
              border: `solid 0.5px ${colors.primary}`,
            }}
          />
        </div>
        <div className="bt-container" id="search-btn-container">
          <button id="search-btn" onClick={handleClick}>
            Pesquisar
          </button>
        </div>
      </div>
      <div id="total">{formatter.format(total)}</div>
      <h2>Histórico de Compras</h2>
      {lastUpdate ? (
        <h3>{`Atualizado em ${lastUpdate.toLocaleDateString()}, às ${lastUpdate.getHours()}:${lastUpdate.getMinutes(
          0,
        )}:${lastUpdate.getSeconds()}`}</h3>
      ) : (
        ''
      )}
      {sales.length > 0 ? <List sales={sales} /> : ''}
    </Container>
  );
}
