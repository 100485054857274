import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Button = styled.button`
  border: none;
  background-color: #f3f0d9;
  font-size: 28px;
  color: ${colors.primary};
  height: 80px;
  width: 90%;
`;

export const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    width: 50%;
  }
  label {
    color: ${colors.primary};
  }
  #newPassword {
    background-color: white;
    border-color: ${colors.primary};
  }
  #passwordConfirmation {
    background-color: white;
    border-color: ${colors.primary};
  }
  button {
    margin-top: 30px;
    border-color: ${colors.primary};
    border: solid 0.5px;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.primary};
    height: 60px;
    width: 25%;
    z-index: 1;
    &:hover {
      background-color: ${colors.primary};
      color: white;
    }
  }

  #forgot-password {
    padding-left: 10px;
    color: ${colors.primary};
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: ${colors.secondary} !important;
    }
  }
`;
