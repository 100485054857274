import * as types from '../types';

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SHOW_LOADING: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }
    case types.DISMISS_LOADING: {
      const newState = { ...initialState };
      return newState;
    }
    default:
      return state;
  }
}
