/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import logo from "../../assets/logo.svg";
import logo2 from "../../assets/logo2.svg";

// MenuItems JSON
const menuItems = [
  { text: "Home", url: "home" },
  { text: "Sobre", url: "about" },
  { text: "Inscrição", url: "https://forms.gle/USX6Pi6ji2vJApqA9" },
  { text: "Prêmios", url: "prizes" },
  { text: "Página do Participante", url: "/login" },
];

function MobileMenu({ onClose }) {
  const handleClick = (id) => {
    const section = document.getElementById(id);
    window.scrollTo({
      top: section.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".main-nav-menu-mobile")) {
        onClose();
      }
    };
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="main-nav-menu-mobile z-50 bg-emerald-900 shadow-sm lg:shadow-none absolute left-0 top-0 bottom-0 right-0 border-r border-emerald-500/30 border-dashed w-[270px] md:w-[300px] lg:flex lg:w-[300px] lg:flex-col">
      <button>
        <div className="header-logo py-4 flex items-center gap-x-3">
          <img src={logo} alt="" className="w-48" />
        </div>
      </button>
      <div className="header-menu lg:hidden">
        <ul className="!flex-col !space-y-5 !px-8 !py-5 !pb-8 !text-[1.1rem] !font-medium !text-rose-100">
          {menuItems.map((menuItem, index) => {
            if (
              menuItem.url.startsWith("/") ||
              menuItem.url.startsWith("http")
            ) {
              return (
                <li key={index}>
                  <a href={menuItem.url}>
                    <button className="!text-rose-100">{menuItem.text}</button>
                  </a>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <button
                    className="!text-rose-100"
                    onClick={() => {
                      handleClick(menuItem.url);
                    }}
                  >
                    {menuItem.text}
                  </button>
                </li>
              );
            }
          })}
        </ul>
        <div className="header-button space-y-2">
          <div className="px-6 lg:flex lg:items-center">
            <a
              href="https://forms.gle/USX6Pi6ji2vJApqA9"
              className="!flex !w-full !items-center !text-emerald-900 justify-center rounded-full !bg-rose-200 !border-rose-300/50 !border-2 !focus:bg-rose-100 !px-6 !py-2.5 !font-semibold !hover:shadow-lg !hover:drop-shadow !transition !duration-200"
            >
              <span>Inscreva-se</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = (event) => {
    event.stopPropagation();
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClick = (id) => {
    const section = document.getElementById(id);
    window.scrollTo({
      top: section.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  return (
    <div className="navbar-home top-0 py-2 px-5 lg:py-5 w-full bg-transparent lg:relative z-50">
      <nav className="bg-white max-w-4xl xl:max-w-5xl mx-auto px-4 py-3 lg:border-none rounded-full">
        <div className="flex items-center justify-between">
          <button>
            <div className="header-logo flex items-center space-x-2">
              <img src={logo2} alt="" className="lg:w-40 w-24" />
            </div>
          </button>
          <div className="header-menu hidden  lg:flex items-center gap-x-10">
            <ul className="!flex !space-x-10 !text-sm !font-bold !text-emerald-900">
              {menuItems.map((menuItem, index) => {
                if (
                  menuItem.url.startsWith("/") ||
                  menuItem.url.startsWith("http")
                ) {
                  return (
                    <li key={index}>
                      <a href={menuItem.url}>
                        <button className="!text-emerald-900">
                          {menuItem.text}
                        </button>
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <button
                        className="!text-emerald-900"
                        onClick={() => {
                          handleClick(menuItem.url);
                        }}
                      >
                        {menuItem.text}
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="flex items-center justify-center lg:hidden">
            <button
              className={`advanced-setting-toggle focus:outline-none ${
                isMobileMenuOpen
                  ? "rounded-full !bg-slate-200 !text-slate-800"
                  : "!text-slate-200"
              }`}
              onClick={handleMobileMenuToggle}
            >
              {isMobileMenuOpen ? (
                <AiOutlineCloseCircle className="text-2xl focus:outline-none active:scale-110 active:text-slate-200" />
              ) : (
                <HiMenuAlt3 className="text-2xl !text-emerald-900 !focus:outline-none !active:scale-110 !active:text-red-500" />
              )}
            </button>
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div className="main-nav-menu fixed inset-0 z-50 transform overflow-auto bg-transparent transition duration-150 lg:hidden">
          <MobileMenu onClose={handleMobileMenuClose} />
        </div>
      )}
    </div>
  );
}

export default Navbar;
