import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  color: #2e2e2e;
  font-size: 14px;
  div {
    padding: 5px;
  }
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    width: 80%;
    height: 200px;
    border-radius: 20px;
    background-color: ${colors.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-weight: bold;
  }
  .msg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
      color: #2e2e2e;
      background: none;
      border: solid 1px #2e2e2e;
      border-radius: 10px;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-left: 7%;
      margin-right: 7%;
      width: 35%;

      :hover {
        background: ${colors.primary};
        filter: brightness(1);
        color: ${colors.secondary};
      }
    }
  }
  @media screen and (min-width: 900px) {
    .box {
      width: 400px;
    }
  }
`;
