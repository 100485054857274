import * as types from '../types';

export function show() {
  return {
    type: types.SHOW_LOADING,
  };
}
export function dismiss() {
  return {
    type: types.DISMISS_LOADING,
  };
}
