import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { Container } from './styled';

export function MaskedInput({
  type,
  placeHolder,
  id,
  mask,
  value,
  setValue,
  label,
  style,
  disabled,
  onBlur,
  onFocus,
  onKeyDown,
  min,
}) {
  const handleTextChange = (text) => setValue(text);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justfyContent: 'center',
      }}
    >
      <Container>
        <label htmlFor={id}>{label}</label>
        <InputMask
          mask={mask}
          maskChar=""
          type={type}
          id={id}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
          placeholder={placeHolder}
          style={style}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          min={min}
        />
      </Container>
    </div>
  );
}

MaskedInput.propTypes = {
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  id: PropTypes.string,
  mask: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  min: PropTypes.number,
};
