export const NAV_TOGGLE = 'NAV_TOGGLE';

export const LOGIN_REQUEST = 'LOGIN_RESQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_RESQUEST';
export const REGISTER_UPDATED_SUCCESS = 'REGISTER_UPDATED_SUCCESS';
export const REGISTER_CREATED_SUCCESS = 'REGISTER_CREATED_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATED_SUCCESS = 'PASSWORD_UPDATED_SUCCESS';

export const PERSIST_REHYDRATE = 'persist/REHYDRATE';

export const SHOW_LOADING = 'SHOW_LOADING';
export const DISMISS_LOADING = 'DISMISS_LOADING';
