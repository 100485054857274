import React from "react";
import { Container } from "./styled";
import trophy from "./res/trophy.svg";
import profile from "./res/profile.svg";
import logout from "./res/logout.svg";
import rules from "./res/rules.svg";
import Summary from "./components/Summary";
import Profile from "./components/Profile";
import { useDispatch } from "react-redux";
import * as actions from "../../store/modules/auth/actions";

export default function Main() {
  const dispatch = useDispatch();

  const [isProfileActive, setIsProfileActive] = React.useState(false);

  const handleProfileIconClick = (bool) => {
    setIsProfileActive(bool);
  };
  return (
    <Container>
      <div id="nav-bar" style={{ zIndex: "1", backgroundColor: "white" }}>
        <img
          src={trophy}
          className={isProfileActive ? "nav-icon" : "nav-icon-active"}
          onClick={handleProfileIconClick.bind(null, false)}
        />
        <img
          src={profile}
          className={isProfileActive ? "nav-icon-active" : "nav-icon"}
          onClick={handleProfileIconClick.bind(null, true)}
        />
        <a
          href="https://drive.google.com/drive/folders/109XcKumU1KU09bnP0HaZ7SJCjSo9YJOB?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          <img src={rules} className="nav-icon" width={30} />
        </a>
        <img
          src={logout}
          className="nav-icon"
          onClick={() => {
            dispatch(actions.loginFailure());
          }}
          width={30}
        />
      </div>
      {!isProfileActive && <Summary />}
      {isProfileActive && <Profile />}
    </Container>
  );
}
