import React, { useEffect, useState } from 'react';
import { Container } from './styled';
import List from './List';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/modules/loading/actions';
import axios from '../../../../services/axios';
import { useSelector } from 'react-redux';

export default function Summary() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.id);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const [sales, setSales] = useState([]);
  const [total, setTotal] = useState('');

  const calcTotal = (data) => {
    let sumCancel = 0;
    let sumDev = 0;

    data.forEach((sale) => {
      sumCancel += sale.Cancels.reduce((accumulator, current) => {
        return accumulator + current.value;
      }, 0);
    });
    data.forEach((sale) => {
      sumDev += sale.Devolutions.reduce((accumulator, current) => {
        return accumulator + current.value;
      }, 0);
    });
    let sumSales = data.reduce((accumulator, current) => {
      return accumulator + current.value;
    }, 0);
    return sumSales - sumCancel - sumDev;
  };

  useEffect(() => {
    async function getData() {
      try {
        dispatch(actions.show());
        const { data } = await axios.get(`/sales/archId/${userId}`);
        setSales(data);
        setTotal(calcTotal(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(actions.dismiss());
      }
    }

    getData();
  }, [dispatch, userId]);

  let lastUpdate = null;

  if (sales[0]) {
    lastUpdate = new Date(sales[0].created_at);
  }

  return (
    <Container>
      <h1 id="title">Compas Acumuladas</h1>
      {total > 0 ? (
        <>
          <div id="total">{`${formatter.format(total)}`}</div>
          <h2>Histórico de Compras</h2>
        </>
      ) : (
        <div style={{ width: '100%', marginLeft: '100px' }}>
          Até o momento, você não possui nenhuma especificação.
        </div>
      )}

      {lastUpdate ? (
        <h3>{`Atualizado em ${lastUpdate.toLocaleDateString()}, às ${lastUpdate.getHours()}:${lastUpdate.getMinutes(
          0,
        )}:${lastUpdate.getSeconds()}`}</h3>
      ) : (
        ''
      )}
      <List sales={sales} />
    </Container>
  );
}
