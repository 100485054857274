import React from 'react';
import flower2 from '../../../assets/flower-2.svg';
import flower3 from '../../../assets/flower-3.svg';

function CallToActionSection() {
  return (
    <section className="cta__section max-w-4xl mx-auto py-24 px-5 lg:px-0 relative">
      <div
        className=" bg-emerald-900 p-10 rounded-2xl
       mx-auto flex flex-col gap-y-5 justify-center items-center relative"
      >
        <img
          className="w-32 absolute -top-2 -right-6 md:top-0 md:right-0 md:w-52"
          src={flower2}
          alt=""
        />
        <img
          className="w-32 absolute bottom-0 -left-4 md:w-52 md:left-0"
          src={flower3}
          alt=""
        />

        <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold max-w-xl mx-auto text-emerald-100 text-center">
          Pronto para crescer junto conosco?
        </h3>
        <p className="text-emerald-100 font-[400] max-w-sm text-base mx-auto text-center">
          Então inscreva-se e participe do Floresça!
        </p>
        <div className="mt-6">
          <a
            href="https://forms.gle/xi5DUd464ZmdVRvm8"
            target="_blank"
            rel="noreferrer"
            className="bg-emerald-200 text-emerald-900 font-bold px-5 py-2 mt-20 rounded-xl shadow-md hover:bg-emerald-600 hover:text-emerald-100 "
          >
            Inscreva-se
          </a>
        </div>
      </div>
    </section>
  );
}

export default CallToActionSection;
