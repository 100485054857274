import styled from 'styled-components';
import * as colors from '../../../../config/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: black;
  background-color: #f5f7f9;
  #title {
    font-size: 28px;
    font-weight: bold;
    padding: 70px 0 30px 0;
  }
  #total {
    font-size: 50px;
    padding: 40px 0 30px 0;
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
  }
  @media (min-width: 900px) {
    #title {
      font-size: 28px;
      font-weight: bold;
      padding: 20px 0 30px 10px;
      width: 100%;
      float: right;
    }
    h2 {
      font-size: 16px;
      font-weight: bold;
      float: right;
      width: 100%;
      padding-left: 10px;
    }
    h3 {
      font-size: 16px;
      float: right;
      width: 100%;
      padding-left: 10px;
    }
  }
  .search-container {
    display: flex;
    width: 100%;
  }
  #search-btn {
    height: 45px;
    width: 150px;
    font-size: 18px;
    margin: 0;
    background-color: ${colors.primary};
    color: #f5f7f9;
    :hover {
      background-color: #f5f7f9;
      color: ${colors.primary};
    }
  }
  #search-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 10px;
  }
  .bt-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
