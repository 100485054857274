/* eslint-disable react/no-children-prop */
import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as A } from 'react-router-dom';
import React from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../public/Logo.svg';
import { StyledButton } from '../StyledButton';
import * as colors from '../../config/colors';
import menu from '../../public/Menu-celular.svg';
import './NavBar.css';

export function NavBar() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });
  return (
    <Navbar
      bg={colors.primary}
      expand="lg"
      sticky="top"
      id={width > 900 ? 'custom-navbar' : 'custom-navbar-cel'}
    >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo do Floresça" style={{ height: '90px' }} />
        </Navbar.Brand>
        <Navbar.Toggle
          children={<img src={menu} alt="Menu" />}
          bsPrefix="navbar-toggler"
          id="nav-toggler"
        />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link>
              <Link
                activeClass="active"
                onClick={() => {
                  scroll.scrollToTop();
                }}
              >
                Home
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="sobre" offset={-200}>
                Sobre
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="premios" offset={-200}>
                Prêmios
              </Link>
            </Nav.Link>
            <Nav.Link style={{ minWidth: '290px' }}>
              <A to="/privacy-policy" id="nav-bar-link">
                Política de Privacidade
              </A>
            </Nav.Link>
            <div id="nav-bt-container">
              <A to="/login">
                <StyledButton className="float-end">
                  Página do Participante
                </StyledButton>
              </A>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
