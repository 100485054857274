import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 0 30px 0;
  width: 95%;
  #month-title {
    border-bottom: solid 1px black;
  }
  .item {
    padding-top: 10px;
    border-bottom: solid 0.5px #b0b6ba;
    padding-bottom: 10px;
  }
  .small-text {
    font-size: 12px;
    color: #343a40;
  }
  .line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media (min-width: 900px) {
    width: 100%;
    overflow: auto;

    .item {
      border-bottom: solid 0.5px #b0b6ba;
    }
    .wrapper {
      margin-left: 10px;
      width: 90%;
      float: left;
      padding-bottom: 30px;
    }
  }
`;
