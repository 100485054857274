import React from 'react';
import subscribe from '../../../assets/subscribe.svg';

function SubscriptionSection() {
  return (
    <section className="max-w-5xl mx-auto flex flex-col gap-x-10 lg:flex-row items-center py-10 px-10">
      <div className="w-full lg:w-1/2">
        <div className="flex-col">
          <h2 className="font-bold text-white text-2xl md:text-3xl lg:text-4xl max-w-lg">
            Como participar do
            <span className="text-rose-900 ml-2">Floresça</span>
          </h2>
          <p className="max-w-lg mx-0 pt-2 text-white">
            Participar do Floresça é bem simples. Basta fazer seu cadastro.
            Depois de inscrito, você já poderá acumular os valores das compras
            que seus clientes fizerem na Elimacol. Para isso, eles devem dizer
            seu nome antes do fechamento da transação.{' '}
          </p>
          <div className="mt-6">
            <a
              href="https://forms.gle/xi5DUd464ZmdVRvm8"
              target="_blank"
              rel="noreferrer"
              className="bg-rose-100 text-rose-900 font-bold px-5 py-2 mt-20 rounded-xl shadow-md hover:bg-rose-900 hover:text-rose-100 "
            >
              Inscreva-se
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 lg:flex items-center justify-centen hidden">
        <img src={subscribe} alt="" className="rounded-xl shadow-lg" />
      </div>
    </section>
  );
}

export default SubscriptionSection;
