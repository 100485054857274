import { createGlobalStyle } from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: 'belda-normal', sans-serif;

  }

  body {
    background: ${colors.primary};
    color: ${colors.secondary};

  }

  /* #root{
    margin-left: 45px;
  } */

  html,  body, #root {
    height: 100%;
  }

  button {
    border-color: ${colors.primary};
  }

  a {
    text-decoration: none!important;
    color: ${colors.primary}
  }

  ul {
    list-style: none!important;
  }

  .Toastify__toast--success {
    background: ${colors.successLight};
    color: ${colors.success};
  }
  .Toastify__toast--info {
    background: ${colors.infoLight};
    color: ${colors.info};
  }
  .Toastify__toast--error {
    background: ${colors.errorLight};
    color: ${colors.error};
  }
  .Toastify__toast--warning {
    background: ${colors.warningLight};
    color: ${colors.warning};
  }
`;
