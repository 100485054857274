import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HeroSection from "./Hero/heroSection";
import AboutSection from "./About/AboutSection";
import SubscriptionSection from "./Subscription/SubscriptionSection";
import PrizesSection from "./PrizesSection/PrizesSection";
import CallToActionSection from "./CallToAction/CallToActionSection";
import "./index.css";
import "./globals.css";

export function Content() {
  return (
    <div>
      <div className="bg-gradient-to-br from-emerald-900 via-emerald-600 to-emerald-100">
        <Navbar />
        <HeroSection />
        <AboutSection />
        <SubscriptionSection />
        <PrizesSection />
        <CallToActionSection />
        <Footer />
      </div>
    </div>
  );
}
