import React from "react";
import { Container } from "./styled";
import add from "./res/add.svg";
import cancel from "./res/cancel.svg";
import all from "./res/all.svg";
import logout from "./res/logout.svg";
import rules from "./res/rules.svg";
import Summary from "./components/Summary";
import Add from "./components/Add";
import Cancel from "./components/Cancel";
import * as actions from "../../store/modules/auth/actions";
import { useDispatch } from "react-redux";

export default function Manager() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState("add");

  const handleClick = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container>
      <div id="nav-bar" style={{ zIndex: "1", backgroundColor: "white" }}>
        <img
          src={add}
          className="nav-icon"
          width={30}
          onClick={handleClick.bind(null, "add")}
        />
        <img
          src={cancel}
          className="nav-icon"
          onClick={handleClick.bind(null, "cancel")}
          width={30}
        />
        <img
          src={all}
          className="nav-icon"
          onClick={handleClick.bind(null, "all")}
          width={30}
        />
        <a
          href="https://drive.google.com/drive/folders/109XcKumU1KU09bnP0HaZ7SJCjSo9YJOB?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          <img src={rules} className="nav-icon" width={30} />
        </a>
        <img
          src={logout}
          className="nav-icon"
          onClick={() => {
            dispatch(actions.loginFailure());
          }}
          width={30}
        />
      </div>
      {activeTab === "all" && <Summary />}
      {activeTab === "add" && <Add />}
      {activeTab === "cancel" && <Cancel />}
    </Container>
  );
}
